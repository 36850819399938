var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body px-lg-5 py-lg-5 signup-profile"},[_c('h5',{staticClass:"card-title text-center"},[_vm._v("CREATE YOUR ACCOUNT")]),(_vm.$store.getters.login_message)?_c('div',[(_vm.$store.getters.login_message != 'Account successfully verified')?_c('base-alert',{attrs:{"type":"info","dismissible":""}},[_vm._v(" "+_vm._s(_vm.$store.getters.login_message))]):_vm._e()],1):_vm._e(),(_vm.account_created)?_c('div',[_c('base-alert',{attrs:{"type":"success"}},[_vm._v("Account created")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.account_created))]),_c('br'),_vm._v(" Please click the link in that email to continue. ")]),_c('p',{staticClass:"text-center"},[_c('base-button',{staticClass:"ntt",attrs:{"size":"lg","block":"","type":"primary","outline":""},on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("Log In")])],1)],1):_c('validation-observer',{ref:"form"},[_c('form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.signup($event)}}},[_c('div',{staticClass:"form-group mb-3"},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{attrs:{"name":"name","placeholder":"Full Name","error":errors[0]},model:{value:(_vm.user.full_name),callback:function ($$v) {_vm.$set(_vm.user, "full_name", $$v)},expression:"user.full_name"}})]}}])})],1),_c('div',{staticClass:"form-group mb-3"},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{attrs:{"name":"email","placeholder":"Email","error":errors[0]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}])})],1),_c('div',{staticClass:"form-group mb-3"},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"ConfirmEmail","rules":"required|email|confirm_email:@Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{attrs:{"placeholder":"Confirm Email","error":errors[0]},model:{value:(_vm.user.confirm_email),callback:function ($$v) {_vm.$set(_vm.user, "confirm_email", $$v)},expression:"user.confirm_email"}})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{attrs:{"name":"password","type":"password","placeholder":"Password","error":errors[0]},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"ConfirmPassword","rules":"required|min:6|password:@Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"password","placeholder":"Confirm Password","error":errors[0]},model:{value:(_vm.user.confirm_password),callback:function ($$v) {_vm.$set(_vm.user, "confirm_password", $$v)},expression:"user.confirm_password"}})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"profession"}},[_vm._v("Profession")]),_c('validation-provider',{attrs:{"name":"Profession","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.loadings.profession)?_c('choices-single',{attrs:{"id":"profession","options":_vm.options.profession},model:{value:(_vm.user.profession),callback:function ($$v) {_vm.$set(_vm.user, "profession", $$v)},expression:"user.profession"}}):_vm._e(),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"marketing-source"}},[_vm._v("How did you find us?")]),_c('validation-provider',{attrs:{"name":"MarketingSource","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.loadings.marketing_source)?_c('choices-single',{attrs:{"id":"marketing-source","options":_vm.options.marketing_source},model:{value:(_vm.user.marketing_source),callback:function ($$v) {_vm.$set(_vm.user, "marketing_source", $$v)},expression:"user.marketing_source"}}):_vm._e(),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}])})],1),_c('div',{staticClass:"form-group"},[_c('div',[_c('label',{attrs:{"for":"reason"}},[_vm._v("Reason for searching")])]),_c('validation-provider',{attrs:{"name":"Reason for searching","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.reason),expression:"user.reason"}],staticClass:"form-control",attrs:{"id":"reason","rows":"3","spellcheck":"false","placeholder":"Reason for searching"},domProps:{"value":(_vm.user.reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "reason", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" This field is required")]):_vm._e()]}}])})],1),_c('div',{staticClass:"custom-control-alternative custom-checkbox pl-0"},[_c('router-link',{staticClass:"float-right",staticStyle:{"position":"relative","z-index":"2","top":"2px"},attrs:{"to":"/terms-and-conditions"}},[_vm._v("More details")]),_c('validation-provider',{attrs:{"name":"TermsAgreed","rules":"terms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-checkbox',{attrs:{"alternative":""},model:{value:(_vm.user.terms_agreed),callback:function ($$v) {_vm.$set(_vm.user, "terms_agreed", $$v)},expression:"user.terms_agreed"}},[_vm._v("Terms of Service")]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("You must agree to our terms")]):_vm._e()]}}])})],1),_c('div',[(_vm.errors.email)?_c('base-alert',{attrs:{"type":"danger"}},[_vm._v("A user with that email address already exist.")]):_vm._e(),(_vm.errors.other)?_c('base-alert',{attrs:{"type":"danger"}},[_vm._v("An unexpected error has occurred")]):_vm._e()],1),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"mt-3 ntt",attrs:{"disabled":_vm.loadings.button,"block":"","type":"login"},on:{"click":function($event){return _vm.signup()}}},[(_vm.loadings.button)?_c('spinner',{staticClass:"spinner-grow-sm",attrs:{"type":"grow"}}):_c('span',[_vm._v("Sign Up")])],1)],1),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"submit"}}),_c('div',{staticClass:"text-center mt-3 text-dark"},[_vm._v(" If you already have an account "),_c('router-link',{staticClass:"mylink",attrs:{"to":"/login"}},[_vm._v(" Log In here ")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }